/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900;1000&display=swap');


@font-face {
    font-family: 'Clearstone Personal Use';
    //font-style: normal;
    //font-weight: normal;
    src:  url('assets/fonts/ClearstonePersonalUse-K7n3X.otf') format('opentype');
    }

// @font-face {
//     font-family: 'Clearstone Personal Use Regular';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Clearstone Personal Use Regular'), url('/assets/fonts/ClearstonePersonalUse-K7n3X.woff') format('woff');
//     }

